










































import {Component, Vue, Watch} from 'vue-property-decorator';
import {Route} from 'vue-router';

import {ElForm} from 'element-ui/types/form';
import {Message} from 'element-ui';

import {validateEmail, validatePassword} from '@/utils/validate';
import {UserStore} from '@/store/modules/user';
import {resetPassword} from '@/firebase/models/users';

@Component
export default class Login extends Vue {
  loginForm = {
    email: '',
    password: '',
  };
  loginRules = {
    email: [{
      required: true, trigger: 'blur', validator: (rule: any, value: any, callback: any) => {
        if (!validateEmail(value)) {
          callback(new Error('Please enter a valid email'));
        } else {
          callback();
        }
      },
    }],
    password: [{
      required: true, trigger: 'blur', validator: (rule: any, value: any, callback: any) => {
        if (!validatePassword(value)) {
          callback(new Error('Please input the password again'));
        } else {
          callback();
        }
      },
    }],
  };

  forgotPassword = false;
  loading = false;

  pwdType = 'password';
  redirect: any = undefined;

  @Watch('$route', {immediate: true})
  OnRouteChange(route: Route) {
    this.redirect = route.query && route.query.redirect;
  }

  showPassword() {
    this.pwdType = this.pwdType === 'password' ? '' : 'password';
  }

  handleLogin() {
    (this.$refs.loginForm as ElForm).validate((valid: boolean) => {
      if (valid) {
        this.loading = true;
        UserStore.login(this.loginForm)
          .then(() => {
            this.loading = false;
            this.$router.push({path: this.redirect || '/'});
          }).catch(() => {
          this.loading = false;
        });
      } else {
        console.error('Login: error submit!!');
        return false;
      }
    });
  }

  passwordReset() {
    resetPassword(this.loginForm.email)
      .then((result: any) => {
        if (result.success) {
          Message({
            type: 'success',
            message: result.success,
          });
          this.forgotPassword = false;
        }
      })
      .catch((error: any) => {
        Message({
          type: 'error',
          message: error.message,
        });
      })
    ;
  }
}
